import { useRouter } from 'next/router'
import styled from 'styled-components'
import Image from 'next/image'
import Link from 'next/link'
import { useContext } from 'react'
import { UserLanguageContext } from './_app'
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded'

const Inner = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 705px);
`

const InnerLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
`

const InnerRight = styled.div``

const PageNotFound = () => {
  const router = useRouter()
  const lang = useContext(UserLanguageContext)

  return (
    <div className={'page_container'}>
      <div className={'section_container'} style={{ paddingBottom: 180 }}>
        <Inner>
          <InnerLeft>
            <div style={{ fontSize: 12, fontWeight: 700 }}>
              <span>Error code : </span>
              <span style={{ color: '#ff4500' }}>404</span>
            </div>
            <span
              style={{
                fontSize: 42,
                fontWeight: 700,
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              Oops!
            </span>
            <span style={{ fontSize: 20, fontWeight: 600 }}>
              Something went wrong.
              <br />
              Please refresh your browser.
            </span>
            <Link href={`/${lang[0]}`}>
              <div
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: '#ff4500',
                  marginTop: 50,
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  width: 'fit-content',
                }}
              >
                <span style={{ marginRight: 10 }}>Go Back Home</span>
                <ArrowForwardRoundedIcon />
              </div>
            </Link>
          </InnerLeft>
          <InnerRight>
            <div style={{ display: 'block', width: 300, height: 377 }}>
              <Image
                src={'/assets/404/404-cat.svg'}
                width={300}
                height={377}
                unoptimized={true}
                objectFit={'cover'}
                alt={'This is stipop 404 page cat illust.'}
                layout={'responsive'}
                sizes={'300px'}
              />
            </div>
          </InnerRight>
        </Inner>
      </div>
    </div>
  )
}

export default PageNotFound
